export interface WindowErrors {
  error: string;
  flashError: string;
  flashSuccess: string;
}

export function emburseLoginEnabled(searchUri: string) {
  // login enabled by local storage
  if (localStorage && localStorage['emburse_login'] === 'true') {return true;}

  // login enabled by url params
  if (!URLSearchParams || !searchUri) {return false;}

  let searchParams = new URLSearchParams(searchUri);

  if (searchParams.get('useEmburseLogin') === 'true') {return true;}

  return false;
}
