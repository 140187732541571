export enum EXPENSE_SORT_FIELDS {
  DATE = 'date',
  CREATED_AT = 'created_at',
  REVIEWED_AT = 'reviewed_at',
  AMOUNT = 'amount',
  STATE = 'state',
  // new fields
  TRANSACTION_DATE = 'transaction_date',
  VENDOR = 'vendor_unicode',
  CATEGORY = 'category',
  USER_ID = 'user_id',
  POSTED_DATE = 'posted_date',
  TRIP_NAME = 'trip_id',
  STATEMENT_CYCLE = 'statement_date',
  EXPENSE_TAG = 'expense_tag_bucket_id'
}

export enum TRANSACTION_SORT_FIELDS {
  VENDOR = 'vendor',
  AMOUNT = 'amount',
  STATE = 'state',
  TRANSACTION_DATE = 'transaction_date',
  CATEGORY = 'category',
  USER_ID = 'user_id',
  POSTED_DATE = 'posted_date',
  STATEMENT_CYCLE = 'statement_date'
}

export enum ALLOWED_REIMBURSABLE_COLUMNS {
  DATE = 'date',
  USER_ID = 'user_id',
  CATEGORY = 'category',
  NOTE = 'note',
  EXPENSE_RULE_WARNINGS = 'expense_rule_warnings',
  IMAGE = 'image',
  AMOUNT = 'amount',
  STATE = 'state',
  MANAGER = 'manager',
  BILLABLE = 'billable',
  TRIP = 'trip',
  BUTTONS = 'buttons'
}

export enum ALLOWED_TRANSACTION_COLUMNS {
  VENDOR = 'vendor',
  AMOUNT = 'amount',
  NOTE = 'note',
  CARD_DETAILS = 'cardDetails',
  CATEGORY = 'category',
  USER_ID = 'user_id',
  IMAGE = 'image',
  EXPENSE_RULE_WARNINGS = 'expense_rule_warnings',
  STATE = 'state',
  MANAGER = 'manager',
  BILLABLE = 'billable',
  SPLIT_TRANSACTION_ID = 'split_transaction_id',
  POSTED_DATE = 'posted_date',
  TRIP = 'trip',
  STATEMENT_DATE = 'statement_date',
  TRANSACTION_DATE = 'transaction_date'
}

export enum ALLOWED_SORT_DIRECTION {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SECTION_NAME {
  REIMBURSABLE_EXPENSE = 'reimbursable_expense',
  CARD_TRANSACTIONS = 'card_transactions'
}

export enum SCOPE {
  TEAM = 'team',
  ME = 'me'
}

export enum PREFERENCE_KEYS {
  COLUMN_ORDER = 'column_order',
  COLUMN_VISIBILITY = 'column_visibility',
  SORT_ORDER = 'sort_order',
  SORT_DIRECTION = 'sort_direction',
  PAGE_SIZE = 'page_size'
}

export interface ColumnInformation {
  id: string;
  type?: 'expense_tag';
  name?: string;
  visible?: boolean;
  width?: number;
}
