// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aR9bHWNaq8lnlX7Hv6Rl7g\\=\\={background-color:var(--white);}.aR9bHWNaq8lnlX7Hv6Rl7g\\=\\= .baseImageContainer{display:inline-block;overflow:hidden;height:100%}@media (min-width:1280px){.aR9bHWNaq8lnlX7Hv6Rl7g\\=\\= .baseImageGrid{display:block;flex-basis:0;-webkit-box-flex:1;flex-grow:1;max-width:100%}}.aR9bHWNaq8lnlX7Hv6Rl7g\\=\\= .baseImage{display:flex;max-width:100%;height:100%;object-fit:cover}.aR9bHWNaq8lnlX7Hv6Rl7g\\=\\= .emburseLogo{position:absolute;width:200px !important;height:auto !important;right:5vw;bottom:48px}", "",{"version":3,"sources":["/app/client_react/src/components/Shared/Account/EmburseAccountsPageContainer.styl"],"names":[],"mappings":"AAEA,4BACE,6BAA6B,CAAA,CAE7B,gDACE,oBAAS,CACT,eAAU,CACV,WAAQ,CAGe,0BACvB,2CACE,aAAS,CACT,YAAY,CACZ,kBAAkB,CAClB,WAAW,CACX,cAAW,CAAA,CAIf,uCACE,YAAS,CACT,cAAW,CACX,WAAQ,CACR,gBAAY,CAGd,yCACE,iBAAU,CACV,sBAAO,CACP,sBAAQ,CACR,SAAO,CACP,WAAQ","file":"EmburseAccountsPageContainer.styl","sourcesContent":["@import '../../../../../client/styles/vars';\n\n:local(.global) {\n  background-color: var(--white);\n\n  .baseImageContainer {\n    display: inline-block;\n    overflow: hidden;\n    height: 100%;\n  }\n\n  @media (min-width: 1280px) {\n    .baseImageGrid {\n      display: block;\n      flex-basis: 0px;\n      -webkit-box-flex: 1;\n      flex-grow: 1;\n      max-width: 100%;\n    }     \n  }\n\n  .baseImage {\n    display: flex;\n    max-width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n\n  .emburseLogo {\n    position: absolute;\n    width: 200px !important;\n    height: auto !important;\n    right: 5vw;\n    bottom: 48px;\n  }\n}"]}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global": "aR9bHWNaq8lnlX7Hv6Rl7g=="
};
module.exports = ___CSS_LOADER_EXPORT___;
